* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
}
button {
    border: none;
    background-color: transparent;
    outline: none;
}
ol,
ul {
    list-style: none;
}

body {
    font-family: gordita_regular;
    font-size: 17px;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}
input,
select,
textarea {
    font-family: gordita_regular;
}

html.modal-enabled {
    overflow: hidden !important;
}
a {
    display: inline-block;
    text-decoration: none;
}

img {
    display: block;
    width: 100%;
}

a,
p,
h1,
h2,
input {
    font-family: gordita_regular;
}
p {
    line-height: 1.6em;
}
.wrapper {
    width: 85%;
    margin: 0 auto;
}

@font-face {
    font-display: auto;
    font-family: gordita_regular;
    src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: gordita_medium;
    src: url(../fonts/gordita/GorditaMedium.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: gordita_bold;
    src: url(../fonts/gordita/GorditaBold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
#viewerBackdrop {
    background: rgba(0, 0, 0, 1);
}
#viewerBackdrop div {
    width: 900px !important;
    margin: 60px auto !important;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
#viewerBackdrop button {
    width: 40px !important;
    height: 50px !important;
    padding: 4px !important;
    background-color: #000;
}
#viewerBackdrop button:hover {
    background-color: #000;
    opacity: 0.9 !important;
}
#viewerBackdrop button span svg {
    fill: #444 !important;
}
#viewerBackdrop button span svg:hover {
    fill: #fff !important;
}
#viewerBackdrop div div button {
    left: 30% !important;
    width: 30px !important;
}
#viewerBackdrop div div button span svg {
    fill: #444 !important;
}
#viewerBackdrop div div button span svg:hover {
    fill: #fff !important;
}
@media all and (max-width: 1280px) {
    .wrapper {
        width: 90%;
    }
    #viewerBackdrop div {
        width: 980px !important;
    }
    #viewerBackdrop div div button {
        left: 0 !important;
        top: 48px !important;
    }
}
@media all and (max-width: 1080px) {
    #viewerBackdrop div {
        width: 920px !important;
    }
    #viewerBackdrop div div button {
        left: 0 !important;
    }
}
@media all and (max-width: 980px) {
    #viewerBackdrop div {
        width: 725px !important;
    }
    #viewerBackdrop div div button {
        top: -7px !important;
    }
}
@media all and (max-width: 768px) {
    #viewerBackdrop div {
        width: 600px !important;
    }
    #viewerBackdrop div div button {
        left: 0% !important;
        top: -54px !important;
    }
}
@media all and (max-width: 640px) {
    #viewerBackdrop div {
        width: 455px !important;
    }
    #viewerBackdrop div div button {
        top: -99px !important;
    }
    #viewerBackdrop button {
        width: 30px !important;
    }
}
@media all and (max-width: 480px) {
    #viewerBackdrop div {
        width: 345px !important;
    }
    #viewerBackdrop div div button {
        top: -127px !important;
        left: -1% !important;
    }
    #viewerBackdrop button {
        top: 56% !important;
    }
}
@media all and (max-width: 360px) {
    #viewerBackdrop div {
        width: 300px !important;
    }
}
@media all and (max-width: 320px) {
}
@media all and (max-width: 480px) {
    .wrapper {
        width: 90%;
    }
}

